import Vue from 'vue'
import VueRouter from 'vue-router'
import MicrioComponent from '@/components/MicrioComponent'
import ContentView from '@/views/ContentView'

import { homeConnections, northConnections, eastConnections, westConnections, southConnections } from './connections'


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'center',
    component: MicrioComponent,
    params: {
      id: 1,
    },
    meta: { keys: 1, color: 'red', micrioId: 'mxRfM', connections: homeConnections, props: true, key: route => `center-${route.meta.micrioId}` },
    children: [{ path: '/about', name: 'about', component: ContentView, meta: { micrioId: 'mxRfM', key: 'center' } }],
  },
  {
    path: '/west',
    name: 'west',
    params: {
      id: 2,
    },
    component: MicrioComponent,
    meta: { keys: 5, color: 'blue', micrioId: 'AGwyZ', connections: westConnections, props: true, key: route => `west-${route.params.id}` },
    children: [
      {
        path: 'about',
        name: 'about',
        component: ContentView,
        meta: {
          micrioId: 'AGwyZ',
          key: 'west'
        }
      },
    ],
  },
  {
    path: '/east',
    name: 'east',
    component: MicrioComponent,
    meta: { color: 'green', micrioId: 'UNkoD', connections: eastConnections, props: true, key: route => `east-${route.params.id}` },
  },
  {
    path: '/north',
    name: 'north',
    component: MicrioComponent,
    meta: { color: 'yellow', micrioId: 'XyEDR', connections: northConnections, props: true, key: route => `north-${route.params.id}` },
  },
  {
    path: '/south',
    name: 'south',
    component: MicrioComponent,
    meta: { color: 'purple', micrioId: 'ErWCT', connections: southConnections, props: true, key: route => `south-${route.params.id}` },
  },
]

const router = new VueRouter({
  routes
})



export default router
